module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-116007538-1","head":true,"anonymize":true},"hotjar":{"trackingId":"1761372","snippetVersion":"6"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Andreas Straub - Portfolio","short_name":"Andreas Straub","start_url":"/","background_color":"#ffffff","theme_color":"#01335D","display":"standalone","icon":"src/images/AS_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a07b13ded5ed590b481b022a1c90de29"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
